import IHeading from "@/components/IHeading"
import ILine from "@/components/ILine"
import IParagraph from "@/components/IParagraph"
import Layout from "@/components/Layout"
import PageHeading from "@/components/PageHeading"
import PageIntro from "@/components/PageIntro"
import Seo from "@/components/seo"
import * as React from "react"
import DesignIcon from "../../images/our-process/Design.svg"
import GrowthIcon from "../../images/our-process/Growth.svg"
import StrategyIcon from "../../images/our-process/Strategy.svg"
import TrendingUp from "@material-ui/icons/TrendingUp"
import Whatshot from "@material-ui/icons/Whatshot"
import Group from "@material-ui/icons/Group"
import Timeline from "@material-ui/icons/Timeline"
import FAQs from "@/components/FAQs"
import CallToAction from "@/components/CallToAction"
import { Security } from "@material-ui/icons"
import { Storage } from "@material-ui/icons"
import { FlightTakeoff } from "@material-ui/icons"
import { Dns } from "@material-ui/icons"
import { TrendingDown } from "@material-ui/icons"
import { HeadsetMic } from "@material-ui/icons"
import { Backup } from "@material-ui/icons"
import { Code } from "@material-ui/icons" 
import { MergeType } from "@material-ui/icons"
import { graphql, PageProps } from "gatsby"


interface CloudPageData {
  mdx: {
    body: string
    excerpt: string
    frontmatter: {
      title: string
      description: string
      iconName: string
      color?: string
    }
  }
}
const CloudPage : React.FC<PageProps<CloudPageData>> = ({ data }) => {
  const { frontmatter, excerpt } = data.mdx
  const { title, description } = frontmatter
  const feature = [
    {
      title: " End-to-end cloud solutions",
    },
    {
      title: "Customized migration strategies",
    },
    {
      title: "Advanced security measures",
    },
    {
      title: "Data management and analytics",
    },
    {
      title: "Cost optimization tools",
    },
    {
      title: "24/7 support and maintenance",
    },
    {
      title: " Disaster recovery and backup solutions",
    },
  ]
  const experties = [
    {
      title: "Years of experience",
      content:
        " With over a decade of experience in cloud technologies, our team has successfully guided hundreds of businesses through their cloud transformation.",
      imgSrc: DesignIcon,
    },
    {
      title: "Notable projects",
      content:
        " Migrated a Fortune 500 company's entire infrastructure to the cloud, resulting in a 30% reduction in operational costs. Implemented a hybrid cloud solution for a healthcare provider, ensuring HIPAA compliance and improving data accessibility. Optimized cloud spending for a fast-growing SaaS startup, saving them 40% on monthly cloud costs.",
      imgSrc: GrowthIcon,
    },
    {
      title: "Certifications and partnerships",
      content:
        "AWS Advanced Consulting Partner. Microsoft Azure Gold Partner. Google Cloud Premier Partner. ISO 27001 Certified.",
      imgSrc: StrategyIcon,
    },
  ]
  const items = [
    {
      imgSrc: <Security />,
      title: "Security Services",
      desc: " Our comprehensive security solutions protect your cloud environment from threats, ensure compliance, and implement best practices for data protection.",
    },
    {
      imgSrc: <FlightTakeoff />,
      title: "Cloud Migration",
      desc: " We offer seamless migration services to help you move your applications, data, and infrastructure to the cloud with minimal disruption to your business operations.",
    },
    {
      imgSrc: <Storage />,
      title: " Data Services",
      desc: "Leverage the power of your data with our advanced analytics, big data processing, and machine learning solutions tailored for cloud environments.",
    },
    {
      imgSrc: <Dns />,
      title: " Cloud Hosting",
      desc: " We provide reliable and scalable hosting solutions on leading cloud platforms, tailored to your specific requirements and performance needs.",
    },
    {
      imgSrc: <TrendingDown />,
      title: "Cloud Cost Optimization",
      desc: " Our cost optimization strategies and tools help you maximize the value of your cloud investment, ensuring you're only paying for what you need.",
    },
    {
      imgSrc: <HeadsetMic />,
      title: "Cloud Support",
      desc: "Our 24/7 support team is always ready to assist you with any cloud-related issues, ensuring your systems run smoothly around the clock.",
    },
    {
      imgSrc: <Backup />,
      title: " Cloud Backup & Recovery",
      desc: "Protect your business continuity with our robust backup and disaster recovery solutions, designed to safeguard your data and minimize downtime.",
    },
    {
      imgSrc: <Code />,
      title: "Development",
      desc: "Our skilled developers can help you build cloud-native applications or modernize existing ones to take full advantage of cloud capabilities.",
    },
    {
      imgSrc: <MergeType />,
      title: "Integration",
      desc: "We specialize in integrating various cloud services and on-premises systems to create a cohesive and efficient IT ecosystem for your business.",
    },
    {
      imgSrc: <TrendingUp />,
      title: "Optimization",
      desc: "Continuously improve your cloud performance with our optimization services, covering everything from application performance to resource allocation.",
    },
  ]
  const helpItems = [
    {
      icon: <Whatshot />,
      title: "Unique selling points",
      content:
        "Holistic approach to cloud services. Vendor-agnostic recommendations. Proven track record of successful implementations. Continuous innovation and adoption of latest cloud technologies.",
    },
    {
      icon: <Group />,
      title: "Team expertise",
      content:
        "  Our team consists of certified cloud architects, security specialists, data engineers, and DevOps professionals with extensive experience across multiple cloud platforms.",
    },
    {
      icon: <Timeline />,
      title: " Process highlights",
      content:
        "Thorough assessment of your current infrastructure and needs. Customized cloud strategy development. Phased implementation approach. Continuous monitoring and optimization. Regular security audits and updates.",
    },
  ]
  const faqData = [
    {
      question: " How long does a typical cloud migration take?",
      answer:
        " The duration varies depending on the complexity and scale of your infrastructure. A small business might be migrated in a few weeks, while a large enterprise could take several months.",
    },
    {
      question:
        "    Can you help us choose between public, private, or hybrid cloud solutions?",
      answer:
        "Absolutely. We assess your specific needs, compliance requirements, and business goals to recommend the most suitable cloud environment for your organization.",
    },
    {
      question: "  How do you ensure the security of our data in the cloud?",
      answer:
        "We implement multi-layered security measures, including encryption, access controls, regular audits, and compliance with industry standards to keep your data secure.",
    },
    {
      question: "What kind of support do you offer after migration?",
      answer:
        "We provide 24/7 monitoring, maintenance, and support to ensure your cloud environment runs smoothly. Our team is always available to address any issues or questions.",
    },
    {
      question: "How can cloud services help reduce our IT costs?",
      answer:
        "Cloud services can reduce costs through pay-as-you-go pricing, elimination of hardware investments, improved resource utilization, and automated scaling based on demand.",
    },
  ]
  return (
    <>
      <Layout>
        <Seo
          title={title}
          description="Custom Magento solutions for scalable, flexible e-commerce. 8+ years of expertise in development, migration, and optimization."
        />
        <div data-aos="fade-up">
                  <PageHeading title={title} description={description}></PageHeading>

        </div>
        <div data-aos="fade-up">
          <PageIntro>
            {excerpt}
          </PageIntro>
        </div>
        <section className="w-full my-16">
          <div className="container mx-auto">
            <div className="w-full">
              <div>
                <div data-aos="fade-up">
                  <IHeading align="left"> Key features</IHeading>
                </div>
              </div>
              <div className="">
                <ILine align="left" />
              </div>
              <div className="grid grid-cols-1 py-10 -mx-1 overflow-hidden md:grid-cols-2 gap-x-10">
                {feature.map((item: any, index: number) => {
                  return (
                    <div
                      className="relative w-full px-6 my-6 overflow-hidden group"
                      key={index}
                      data-aos="fade-up"
                    >
                      <IHeading size="three">{item.title}</IHeading>
                      <IParagraph>{item.content}</IParagraph>
                      <div
                        className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                        style={{ zIndex: "-1" }}
                      ></div>
                    </div>
                  )
                })}
              </div>
              <div data-aos="fade-up">
                <IHeading size="two">Target market</IHeading>
              </div>
              <div data-aos="fade-up">
                <IParagraph align="left">
                  Our services cater to businesses across various industries,
                  from small startups to large enterprises, looking to leverage
                  cloud technology for improved efficiency, scalability, and
                  innovation.
                </IParagraph>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full pt-12 pb-24 my-20 bg-gray-800 lg:my-36 md:pt-24 dark:bg-primary-900">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center" color="white">
                Our Expertise
              </IHeading>
            </div>
            <div data-aos="fade-up">
              <IParagraph align="center" color="white">
                With over a decade of experience in e-commerce solutions, our
                team has established itself as a trusted Salesforce Commerce
                Cloud implementation partner.
              </IParagraph>
            </div>
            <div className="grid grid-cols-1 mt-20 md:grid-cols-2 lg:grid-cols-3 gap-x-10 xl:gap-x-20 gap-y-20">
              {experties.map((item: any, index: number) => {
                return (
                  <div
                    className="relative p-6 pt-10 duration-300 border-4 border-gray-600 group md:p-8 lg:pt-16 hover:border-white"
                    key={index}
                    data-aos="fade-up"
                  >
                    <div className="absolute px-4 bg-gray-800 help-icon-box dark:bg-primary-900 -top-10">
                      <img src={item.imgSrc} alt="dddd" />
                    </div>
                    <IHeading size="subHeader" color="white">
                      {item.title}
                    </IHeading>
                    <IParagraph color="gray">
                      <ul className="py-4 space-y-3 list-disc">
                        {item.content
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </IParagraph>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="w-full my-20">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">Services Offered</IHeading>
            </div>
            <div className="grid grid-cols-1 mb-12 lg:grid-cols-2 gap-x-10 gap-y-28 mt-36">
              {items.map((item: any) => {
                return (
                  <div
                    className="flex flex-col items-center p-5 rounded-lg shadow-xl bg-slate-700"
                    data-aos="fade-up"
                  >
                    <div className="flex items-center justify-center mb-6 -mt-20 overflow-hidden rounded-full icon-box w-28 md:w-40 h-28 md:h-40 bg-slate-200">
                      {item.imgSrc}
                    </div>
                    <IHeading size="subHeader" color="white">
                      {item.title}
                    </IHeading>
                    <div className="mt-3 mb-6">
                      <ILine />
                    </div>
                    <IParagraph color="gray">
                      <ul className="px-16 space-y-3 list-disc">
                        {item.desc
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </IParagraph>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="w-full my-28">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">
                {" "}
                Why Choose Us for Cloud Services
              </IHeading>
            </div>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 mt-14">
              {helpItems.map((item: any, index: number) => {
                return (
                  <div
                    className="relative flex flex-col items-center p-10 overflow-hidden border-b-2 rounded-md shadow-xl group border-slate-600 dark:border-primary-400 dark:bg-primary-900"
                    key={index}
                    data-aos="fade-up"
                  >
                    <div className="z-10 mb-6 startup-help-icon-box">
                      {item.icon}
                    </div>
                    <IHeading size="subHeader" align="text-center z-10">
                      {item.title}
                    </IHeading>
                    <p className="absolute top-0 bottom-0 left-0 right-0 z-0 p-8 text-gray-200 duration-300 opacity-0 bg-slate-600 dark:bg-primary-600 group-hover:opacity-100 group-hover:z-20">
                      <ul className="space-y-3 list-disc ">
                        {item.content
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <FAQs faqs={faqData} />
        <CallToAction
          title="Get Started with Cloud"
          description="Ready to start your cloud journey? Contact us today for a free consultation and let our experts help you harness the full potential of cloud technology for your business."
          cta="Start Your Cloud Journey"
          ctaTo="/contact-us"
        />
      </Layout>
    </>
  )
}

export const query = graphql`
query CloudPageQuery {
  mdx(
    fileAbsolutePath: { regex: "/(technologies)/" }
    slug: { regex: "/(technologies/cloud)/" }
  ) {
    excerpt(pruneLength: 1000)
    frontmatter {
      title
      iconName
      description
    }
  }
}
`
export default CloudPage
